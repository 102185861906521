<template>
  <aside class="porque">
    <div class="bb-container">
      <p class="porque__header">¿Por qué comprar en HelloBB?</p>
      <div class="porque__grid">
        <div class="porque__item" v-for="porque in porques" :key="porque.title">
          <img
            loading="lazy"
            class="porque__ico"
            :src="'/' + porque.ico"
            :alt="porque.title"
          />
          <div class="porque__content">
            <p class="porque__title" v-html="porque.title"></p>
            <div class="porque__description" v-html="porque.description"></div>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
export default {
  name: "CataloguePorque",
  data() {
    return {
      porques: [
        {
          ico: "ico-porque-mejor.svg",
          title: "Sabemos qué es lo mejor",
          description:
            "Nuestro catálogo se basa en lo que escogen las familias que hacen sus listas de nacimiento con nuestra aplicación.",
        },
        {
          ico: "ico-porque-envios.svg",
          title: "Envíos gratis",
          description: "Te mandamos tus pedidos gratis a partir de 50€.",
        },
        {
          ico: "ico-porque-devoluciones.svg",
          title: "Devoluciones fáciles",
          description:
            "Si algo no te convence, puedes devolvérnoslo en los 2 meses siguientes a su compra.",
        },
      ],
    };
  },
};
</script>
